<template>
  <div
    ref="fadeInContainer"
    class="fade-in-background"
    :style="{
      background: background
    }"
  >
    <div
      v-if="showContentIf"
      class="fade-in-container"
      :class="{ animation: showContent, 'hide-block': !showContent }"
      :style="{
        '--animation-duration': `${duration}s`,
        '--animation-transform': `${transform}px`
      }"
    >
      <slot />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  aniShowTop: {
    type: Number,
    default: 50
  },
  aniHideTop: {
    type: Number,
    default: 50
  },
  duration: {
    type: Number,
    default: 1
  },
  transform: {
    type: Number,
    default: 200
  },
  background: {
    type: String,
    default: "transparent"
  },
  useIf: {
    type: Boolean,
    default: false
  },
  delay: {
    type: Number,
    default: 0
  }
})

const showContent = ref<boolean>(false)

const showContentIf = ref<boolean>(true)

// const fadeInContainer = "fade-in-container"

const fadeInContainer = ref()

onMounted(() => {
  if (props.useIf) {
    showContentIf.value = false
  }
  handleScroll()
  nextTick(() => {
    window.addEventListener("scroll", handleScroll, true)
  })
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll, true)
})

const handleScroll = () => {
  nextTick(() => {
    const domRef: any = fadeInContainer.value
    if (!domRef) {
      return
    }
    const position = domRef.getBoundingClientRect().top
    const clientHeight = document.documentElement.clientHeight
    if (props.useIf) {
      showContent.value = true
      let timeoutObj
      if (showContentIf.value) {
        // 效果优势
        clearTimeout(timeoutObj)
        showContentIf.value = position + props.aniHideTop <= clientHeight
      } else {
        timeoutObj = setTimeout(() => {
          showContentIf.value = position + props.aniShowTop <= clientHeight
        }, props.delay)
      }
    } else {
      showContentIf.value = true
      if (showContent.value) {
        // 效果优势
        showContent.value = position + props.aniHideTop <= clientHeight
      } else {
        showContent.value = position + props.aniShowTop <= clientHeight
      }
    }
  })
}
</script>

<style lang="scss" scoped>
.fade-in-background {
  .fade-in-container {
    opacity: 0;
    // transform: translateY(var(--animation-transform));
  }

  .animation {
    opacity: 1;
    transition: all var(--animation-duration);
    animation: fadeIn backwards var(--animation-duration) ease;
  }
  .hide-block {
    opacity: 0;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(var(--animation-transform));
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
</style>
